import React, { useState } from "react";
import styled from "styled-components";

import { Maybe, SanityAttributeBlock, SanityBlockContent } from "@graphql-types";
import { Container } from "@util/standard";
import BlocksContent from "../blocksContent";
import {
  assets,
  colors,
  fontWeights,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { sortProductAttributes } from "@util/helper";

interface Props {
  data: SanityAttributeBlock[];
}

const Wrapper = styled(Container)`
  width: 100%;
  flex-wrap: wrap;
  margin: 40px 0 0 0;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    flex-wrap: initial;
    overflow-x: scroll;
  }
`;

const AttributeWrapper = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  opacity: 1;
  width: calc(100% / 7);
  min-width: 65px;
  margin-right: 10px;
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};

  &:hover {
    opacity: 1;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin-right: 10px;
    &:hover {
      img {
        filter: none;
      }
      p {
        color: ${colors.navy};
      }
    }
  }
`;

const AttributeIcon = styled.img`
  opacity: 1;
  margin-right: 44px;
  width: 34px;
  height: 40px;
`;

const AttributeName = styled.p`
  color: ${colors.navy};
  font-weight: ${fontWeights.medium};
`;

const TextContainer = styled(Container)`
  background: ${colors.cream};
  padding: 30px;
  flex-direction: column;
  row-gap: 10px; 

  h4,
  h3,
  h2,
  h1 {
    margin: 0 !important;
  }
`;

function AttributesBlock({ data }: Props) {
  const [currentAttribute, setCurrentAttribute] = useState(0);
  if (!data) return null;

  const attributeHasText =
    currentAttribute != null ? data[currentAttribute]?.attributeText : undefined;

  const sortedAttributes = sortProductAttributes(data);

  return (
    <>
      {/* {data && data.length > 0 && (
        <Container margin="20px 0 10px 0">
          <Icon src={assets.cursor} />
          <h3>Click on icon for info</h3>
        </Container>
      )} */}
      <Wrapper>
        {sortedAttributes &&
          sortedAttributes.map((attribute, index) => {
            if (attribute == null) return null;
            const selected = currentAttribute != null && index === currentAttribute;
            const attributeHasIcon = Boolean(attribute.attribute?.icon?.asset);

            return (
              <AttributeWrapper
                selected={selected}
                onClick={() => setCurrentAttribute(index)}
                key={attribute._key}
              >
                <AttributeIcon
                  src={
                    attributeHasIcon
                      ? attribute?.attribute?.icon?.asset?.url
                      : assets.attributeDefaultIcon
                  }
                />
                <AttributeName>{attribute.attribute?.title}</AttributeName>
              </AttributeWrapper>
            );
          })}
      </Wrapper>
      {attributeHasText && (
        <TextContainer backgroundColor="cream" margin="10px 0 0 0">
          {/* {data[currentAttribute]?.attribute?.title && <h4>{data[currentAttribute]?.attribute?.title}</h4>} */}
          <BlocksContent
            data={
              currentAttribute != null
                ? (data[currentAttribute].attributeText as Maybe<SanityBlockContent>)
                : undefined
            }
          />
        </TextContainer>
      )}
    </>
  );
}

export default AttributesBlock;
